html, body, #root {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-transition: green 5000s ease-in-out 0s;
  transition: green 5000s ease-in-out 0s;
}

.hide-scroll {
  &::-webkit-scrollbar{
    display: none;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
}
