.disclaimers-text {
  p {
    margin-bottom: 1rem;
  }

  h4 {
    font-weight: 700;
  }

  a {
    color: #3D65F6;
    text-decoration: underline;
  }
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #D1D1D6;
  color: #D1D1D6;
  box-shadow: 9984px 0 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  }
  50% {
    box-shadow: 9984px 0 0 0 #D1D1D6, 9999px -10px 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px -10px 0 0 #D1D1D6;
  }
  100% {
    box-shadow: 9984px 0 0 0 #D1D1D6, 9999px 0 0 0 #D1D1D6, 10014px 0 0 0 #D1D1D6;
  }
}